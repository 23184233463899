import { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleDown } from "@fortawesome/free-solid-svg-icons";
import { useNavigate } from "react-router-dom";

const Dropdown = ({ items }) => {
  return (
    <div className="dropdown-menu absolute bg-white text-black w-full left-0 mt-2 p-2 rounded shadow z-50">
      {items.map((item, index) => (
        <div key={index} className="dropdown-item py-1">
          {item}
        </div>
      ))}
    </div>
  );
};

const ButtonComponent = ({ src, name, dropdownItems = [], links }) => {
  const [isOpen, setIsOpen] = useState(false);
  const navigate = useNavigate()

  const handleClick = () => {
    setIsOpen(!isOpen);
    navigate('/create-template', { state: { collectionName: "my first collection", fileNames: links, toggleRectangle: true } });
  };

  return (
    <div className="relative inline-block">
      <button
        onClick={handleClick}
        className="inline-flex justify-evenly items-center shadow-xl hover:shadow-inner bg-white rounded-xl border-2  border-dashed w-full py-2 px-3 focus:outline-none hover:border-black hover:bg-pastel-green text-xs lg:text-xl"
      >
        <img src={src} alt={name} className="select-none mx-2" />
        <h4 className="text-black text-xs lg:mx-3 hidden lg:block ">
          {name}
        </h4>
        <FontAwesomeIcon
          className={`text-black select-none hidden ${
            isOpen ? "spin rotate-180" : "reverse-spin"
          }`}
          icon={faAngleDown}
        />
      </button>
      {/* {isOpen && <Dropdown items={dropdownItems} />} */}
    </div>
  );
};

export default ButtonComponent;
