// FileHandler.js
import { useRef, useState } from "react";
import { useNavigate } from "react-router-dom";

const FileHandler = ({ children }) => {
  const fileInputRef = useRef(null);
  const dropRef = useRef(null);
  const [dragging, setDragging] = useState(false);
  const dragCounter = useRef(0);
  const [files, setFiles] = useState([]);
  const navigate = useNavigate();

  const handleDragOver = (e) => {
    e.preventDefault();
  };

  const handleFileUpload = (e) => {
    let uploadedFiles = [...e.target.files];
    setFiles((prevFiles) => [...prevFiles, ...uploadedFiles]);
  };

  const handleDrag = (e) => {
    e.preventDefault();
    e.stopPropagation();
  };

  const handleDragIn = (e) => {
    e.preventDefault();
    e.stopPropagation();
    dragCounter.current++;
    if (e.dataTransfer.items && e.dataTransfer.items.length > 0) {
      setDragging(true);
    }
  };

  const handleFileRemove = (index) => {
    // new handler for removing files
    setFiles((prevFiles) => prevFiles.filter((file, i) => i !== index));
  };

  const handleDragOut = (e) => {
    e.preventDefault();
    e.stopPropagation();
    dragCounter.current--;
    if (dragCounter.current === 0) {
      setDragging(false);
    }
  };

  const handleDrop = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setDragging(false);
    if (e.dataTransfer.items && e.dataTransfer.items.length > 0) {
      for (let i = 0; i < e.dataTransfer.items.length; i++) {
        const item = e.dataTransfer.items[i].webkitGetAsEntry();
        if (item) {
          traverseFileTree(item);
        }
      }
      e.dataTransfer.clearData();
      dragCounter.current = 0;
    }
  };

  const traverseFileTree = (item, path = "") => {
    if (item.isFile) {
      // Get file
      item.file((file) => {
        setFiles((prevFiles) => [...prevFiles, file]);
      });
    } else if (item.isDirectory) {
      // Get folder contents
      const dirReader = item.createReader();
      dirReader.readEntries((entries) => {
        for (let i = 0; i < entries.length; i++) {
          traverseFileTree(entries[i], path + item.name + "/");
        }
      });
    }
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    navigate('/create-template', { state: { collectionName: "my first collection", fileNames: files, toggleRectangle: true } });
  };

  const handleFiles = (files) => {
    // Convert the FileList to an array
    const fileArray = Array.from(files);

    // Process the files here. This could involve setting the state, sending the files to a server, etc.
    // For example, to add the files to the state:
    setFiles((prevFiles) => [...prevFiles, ...fileArray]);
  };

  const handlePaste = (event) => {
    if (event.clipboardData) {
      const items = event.clipboardData.items;
      let filesToUpload = [];

      for (let i = 0; i < items.length; i++) {
        if (
          items[i].kind === "file" &&
          (items[i].type.indexOf("image") !== -1 ||
            items[i].webkitGetAsEntry().isDirectory)
        ) {
          const file = items[i].getAsFile();
          filesToUpload.push(file);
        }
      }

      if (filesToUpload.length > 0) {
        handleFiles(filesToUpload);
      }
    }
  };

  return children({
    fileInputRef,
    dropRef,
    dragging,
    setDragging,
    dragCounter,
    files,
    setFiles,
    handleDragOver,
    handleFileUpload,
    handleDrag,
    handleDragIn,
    handleFileRemove,
    handleDragOut,
    handleDrop,
    traverseFileTree,
    handleSubmit,
    handleFiles,
    handlePaste,
  });
};

export default FileHandler;
