export const DBConfig = {
    name: "templateDB",
    version: 1,
    objectStoresMeta: [
      {
        store: "templateDB",
        storeConfig: { keyPath: "id", autoIncrement: true },
        storeSchema: [
          { name: "filename", keypath: "filename", options: { unique: true } },
          { name: "collection", keypath: "collection", options: { unique: false } },
          { name: "image_file", keypath: "image_file", options: { unique: false } },
          { name: "rectangle_coords", keypath: "rectangle_coords", options: { unique: false } },
          { name: "rectangle_dims", keypath: "rectangle_dims", options: { unique: false } },
          { name: "rectangle_orientation", keypath: "rectangle_orientation", options: { unique: false } },
          { name: "file_object", keypath: "file_object", options: { unique: false } },
        ],
      },
    ],
  };