import "../App.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCheck,
  faClock,
  faWrench,
  faLock,
} from "@fortawesome/free-solid-svg-icons";
import ButtonComponent from "./components/templateButton";
import buttonData from "./components/buttonDataArray";
import FileHandler from "./components/fileHandler";
import HowTo from "./howTo";
import { motion } from "framer-motion";
import { useInView } from "react-intersection-observer";
import Footer from "./footer";

function Landing() {
  const [ref1, inView1] = useInView({
    triggerOnce: true,
    threshold: 0.5,
  });
  const [ref2, inView2] = useInView({
    triggerOnce: true,
    threshold: 0.5,
  });
  const [ref3, inView3] = useInView({
    triggerOnce: true,
    threshold: 0.5,
  });

  const [ref4, inView4] = useInView({
    triggerOnce: true,
    threshold: 0.5,
  });
  return (
    <FileHandler>
      {({
        fileInputRef,
        dropRef,
        dragging,
        files,
        handleDragOver,
        handleFileUpload,
        handleDrag,
        handleDragIn,
        handleFileRemove,
        handleDragOut,
        handleDrop,
        handleSubmit,
        handlePaste,
      }) => (
        <div
          onDragEnter={handleDragIn}
          onDragLeave={handleDragOut}
          onDragOver={handleDrag}
          onDrop={handleDrop}
          onPaste={handlePaste}
        >
          {dragging && (
            <div
              style={{
                border: "dashed white 4px",
                backgroundColor: "#333333",
                position: "fixed",
                top: 0,
                bottom: 0,
                left: 0,
                right: 0,
                zIndex: 9999,
                opacity: 0.5,
              }}
            >
              <div
                style={{
                  position: "absolute",
                  top: "50%",
                  right: 0,
                  left: 0,
                  textAlign: "center",
                  color: "white",
                  fontSize: 36,
                }}
              >
                <div className="opacity-100">Drop your mockups here</div>
              </div>
            </div>
          )}
          <div className="App w-screen h-screen lg:-mt-8">
            <header
              id="background"
              className="h-screen lg:bg-cover lg:bg-center mb-56"
            >
              <div id="moving-background class"></div>
              <section id="" className="text-gray-600 body-font">
                <div className="container mx-auto flex px-5 py-24 items-center flex-col lg:flex-row">
                  <div className="mt-24 md:mt-32 lg:mt-12 flex-grow w-full ml-6 lg:w-1/3 pr-16 flex flex-col items-center lg:items-start text-center lg:text-left mb-16 justify-center lg:justify-start mx-auto lg:mx-0 md:text-xl">    
                    <div className="lg:hidden flex flex-col md:flex-row md:justify-center lg:flex-col items-center lg:items-start gap-6 md:gap-8 md:max-w-2xl lg:max-w-lg">
                      <video muted loop autoPlay playsInline preload="auto" className="w-full h-auto rounded-3xl max-w-[320px] mb-6 lg:max-w-[420px]">
                        <source src="demo.mp4" type="video/mp4" />
                      </video>
                      <div className="flex flex-col gap-4">
                      </div>
                    </div>
                    <h1 className="title-font text-4xl md:text-5xl mb-4 font-medium text-gray-900">
                      Make mockups even{" "}
                      <br className="hidden md:inline-block" />
                      easier for your products
                    </h1>
                    <p className="mt-6 mb-4 leading-relaxed">
                      <FontAwesomeIcon icon={faCheck} className="select-none" />{" "}
                      100% Customizable to <br className="md:hidden"></br>your liking.
                    </p>
                    <p className="mb-4 leading-relaxed">
                      <FontAwesomeIcon icon={faCheck} className="select-none" />{" "}
                      Uploadable to e-commerce sites.
                    </p>
                    <p className="mb-6 leading-relaxed">
                      <FontAwesomeIcon icon={faCheck} className="select-none" />{" "}
                      Quick and easy to use.
                    </p>
                    <span className="hidden sm:block text-xl mb-4">
                      No mockups to use? Here are some sets:
                    </span>
                    <div className="hidden sm:grid grid-cols-4 gap-4 ">
                      {buttonData.map((button, index) => (
                        <ButtonComponent src={button.src} name={button.name} links={button.links}/>
                      ))}
                    </div>
                    <span className="w-5/6 mt-5 text-sm hidden lg:block">
                      *By uploading an image or URL you agree to our <a href="./terms_of_service_templit.pdf" rel="noreferrer" target="_blank">Terms of Service.</a>
                      {/* This site is protected by hCaptcha and its
                      Privacy Policy and Terms of Service apply. */}
                    </span>{" "}
                  </div>
                  <div
                    id="uploadBox"
                    className="relative bg-white lg:w-1/3 h-96 rounded-3xl border-8 border-double shadow-2xl flex justify-center items-center mr-3 lg:mx-0 ml-6 "
                    ref={dropRef}
                    onDragOver={handleDragOver}
                    onDrop={handleDrop}
                  >
                    <input
                      type="file"
                      multiple
                      webkitdirectory=""
                      style={{ display: "none" }}
                      ref={fileInputRef}
                      onChange={handleFileUpload}
                    />
                    {files.length > 0 ? (
                      <div className="absolute top-0 flex flex-col justify-between w-full h-full overflow-y-auto">
                        <div>
                          {files.map((file, index) => (
                            <div
                              key={index}
                              className="border-black border-x-0 border-b-2"
                            >
                              <div className="flex justify-between w-full  my-2">
                                <span
                                  className="ml-4 cursor-pointer hover:text-light-blue"
                                  onClick={() =>
                                    window.open(
                                      URL.createObjectURL(file),
                                      "_blank"
                                    )
                                  }
                                >
                                  {file.name}
                                </span>
                                <button
                                  onClick={() => handleFileRemove(index)}
                                  className="hover:text-light-blue mr-4"
                                >
                                  x
                                </button>
                              </div>
                            </div>
                          ))}
                        </div>
                        <div className="flex flex-row justify-center mb-4">
                          <button
                            className="bg-pastel-purple rounded-full w-1/4 p-3 px-4 hover:shadow-2xl justify-center items-center mt-4 hover:bg-dark-pastel-purple"
                            onClick={handleSubmit}
                          >
                            <h4 className="text-2xl text-black ">Submit</h4>
                          </button>
                        </div>
                      </div>
                    ) : (
                      <div className="flex flex-col">
                        <div className="flex flex-col justify-center items-center">
                          <button
                            className="hidden lg:block bg-pastel-purple rounded-full p-3 px-4 hover:shadow-2xl justify-center items-center hover:bg-dark-pastel-purple"
                            onClick={() => fileInputRef.current.click()}
                          >
                            <h4 className="text-2xl text-black opacity-100">
                              Upload Your Mockups Here
                            </h4>
                          </button>
                        </div>
                        <div className="flex flex-row justify-center">
                          <span className="hidden lg:block text-xl mt-4 w-2/3 opacity-80">
                            Drag or paste all of your images separately or as a folder
                          </span>
                          <span className="lg:hidden text-xl mt-4 w-2/3 opacity-100">
                            We support desktops only at the moment, but we&apos;re working on mobile/tablet support!
                          </span>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </section>

              <HowTo />

              <section id="sellingPoints" className="text-gray-600 body-font">
                <div className="flex relative flex-row justify-center pt-10 items-center md:w-2/3 mx-auto rounded-full">
                  <h1 className="title-font mt-24 flex flex-row justify-center text-4xl md:text-6xl text-black opacity-80 mx-12">
                    So, what makes us better?
                  </h1>
                </div>
                <div className="">
                <div className="container px-5 py-20 mx-auto flex flex-wrap justify-center">
                  <div className="lg:w-1/2 w-full mb-10 lg:mb-0 rounded-lg overflow-hidden flex flex-row justify-center">
                    <motion.img
                      initial={{ x: "-100vw" }}
                      animate={{ x: 0 }}
                      transition={{
                        type: "keyframes",
                        stiffness: 120,
                        duration: 2.0,
                      }}
                      alt="feature"
                      className="object-cover object-center h-full w-fit rounded-3xl select-none"
                      src="./sellingPoints.svg"
                    />
                  </div>
                  <div className="flex flex-col flex-wrap lg:py-6 -mb-10 lg:w-5/12 lg:pl-12 lg:text-left text-center">

                    <motion.div
                      ref={ref1}
                      className="flex flex-col lg:items-start items-center"
                      initial={{ opacity: 0, scale: 0.9 }}
                      animate={{
                        opacity: inView1 ? 1 : 0,
                        scale: inView1 ? 1 : 0.9,
                      }}
                      transition={{ duration: 0.5 }}
                    >
                      <div className="flex flex-col lg:items-start items-center bg-dark-pastel-blue rounded-3xl p-6 w-full lg:w-full mb-4">
                        <div className="w-12 h-12 inline-flex items-center justify-center rounded-full bg-white mb-3">
                          <FontAwesomeIcon
                            icon={faClock}
                            className="text-blue-500"
                          />
                        </div>
                        <div className="flex-grow">
                          <h2 className="text-gray-900 text-lg title-font font-medium mb-3">
                            We are quick and efficient
                          </h2>
                          <p className="leading-relaxed text-base">
                          Upload multiple listings worth of images in seconds for swift productivity.
                          </p>
                        </div>
                      </div>
                    </motion.div>

                    <motion.div
                      ref={ref2}
                      className="flex flex-col lg:items-start items-center"
                      initial={{ opacity: 0, scale: 0.9 }}
                      animate={{
                        opacity: inView2 ? 1 : 0,
                        scale: inView2 ? 1 : 0.9,
                      }}
                      transition={{ duration: 0.5 }}
                    >
                      <div className="flex flex-col lg:items-start items-center bg-dark-pastel-blue rounded-3xl p-6 w-full lg:w-full mb-4">
                        <div className="w-12 h-12 inline-flex items-center justify-center rounded-full bg-white mb-3">
                          <FontAwesomeIcon
                            icon={faWrench}
                            className="text-blue-500"
                          />
                        </div>
                        <div className="flex-grow">
                          <h2 className="text-gray-900 text-lg title-font font-medium mb-3">
                            We make things easy to organize
                          </h2>
                          <p className="leading-relaxed text-base">
                          Simplify your workflow with drag-and-drop functionality, organizing your visuals seamlessly.
                          </p>
                        </div>
                      </div>
                    </motion.div>
                    <motion.div
                      ref={ref3}
                      className="flex flex-col mb-10 lg:items-start items-center"
                      initial={{ opacity: 0, scale: 0.9 }}
                      animate={{
                        opacity: inView3 ? 1 : 0,
                        scale: inView3 ? 1 : 0.9,
                      }}
                      transition={{ duration: 0.5 }}
                    >
                      <div className="flex flex-col lg:items-start items-center bg-dark-pastel-blue rounded-3xl p-6 w-full">
                        <div className="w-12 h-12 inline-flex items-center justify-center rounded-full bg-white mb-3">
                          <FontAwesomeIcon
                            icon={faLock}
                            className="text-blue-500"
                          />
                        </div>
                        <div className="flex-grow">
                          <h2 className="text-gray-900 text-lg title-font font-medium mb-3">
                            We keep your designs secure
                          </h2>
                          <p className="leading-relaxed text-base">
                          Your designs are safe with us - we don&apos;t store any of your creative work for maximum privacy.
                          </p>
                        </div>
                      </div>
                    </motion.div>
                  </div>
                </div>
                </div>
              </section>
              
              <section
                id=""
                className="text-gray-600 py-24 body-font bg-gradient-to-b from-pastel-blue to-dark-pastel-blue"
              >
                <div className="flex relative flex-row justify-center pt-10 pb-10 items-center md:w-2/3 mx-auto rounded-full">
                  <h1 className="title-font flex flex-row justify-center text-4xl md:text-6xl text-black opacity-80 mx-12">
                    What people are saying:
                  </h1>
                </div>

                <div className="container px-5 py-8 mx-auto">
                  <div
                    id="testimonialCard"
                    className="xl:w-1/2 lg:w-3/4 w-full mx-auto text-center bg-white p-4 pb-8 md:p-8 rounded-3xl mb-24"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="currentColor"
                      className="inline-block w-8 h-8 text-dark-pastel-purple mb-8"
                      viewBox="0 0 975.036 975.036"
                    >
                      <path d="M925.036 57.197h-304c-27.6 0-50 22.4-50 50v304c0 27.601 22.4 50 50 50h145.5c-1.9 79.601-20.4 143.3-55.4 191.2-27.6 37.8-69.399 69.1-125.3 93.8-25.7 11.3-36.8 41.7-24.8 67.101l36 76c11.6 24.399 40.3 35.1 65.1 24.399 66.2-28.6 122.101-64.8 167.7-108.8 55.601-53.7 93.7-114.3 114.3-181.9 20.601-67.6 30.9-159.8 30.9-276.8v-239c0-27.599-22.401-50-50-50zM106.036 913.497c65.4-28.5 121-64.699 166.9-108.6 56.1-53.7 94.4-114.1 115-181.2 20.6-67.1 30.899-159.6 30.899-277.5v-239c0-27.6-22.399-50-50-50h-304c-27.6 0-50 22.4-50 50v304c0 27.601 22.4 50 50 50h145.5c-1.9 79.601-20.4 143.3-55.4 191.2-27.6 37.8-69.4 69.1-125.3 93.8-25.7 11.3-36.8 41.7-24.8 67.101l35.9 75.8c11.601 24.399 40.501 35.2 65.301 24.399z"></path>
                    </svg>
                    <motion.section
                      ref={ref4}
                      id="testimonial"
                      className="text-gray-600 body-font "
                      initial={{ opacity: 0, scale: 0.9 }}
                      animate={{
                        opacity: inView4 ? 1 : 0,
                        scale: inView4 ? 1 : 0.9,
                      }}
                      transition={{ duration: 0.5 }}
                    >
                      <p className="leading-relaxed text-lg mx-12">
                        I loved using templit! It was so easy to use and I was
                        able to generate mockups for my entire store in just a
                        few minutes.
                      </p>
                      <span className="inline-block h-1 w-10 rounded bg-dark-pastel-purple mt-8 mb-6"></span>
                      <h2 className="text-gray-900 font-medium title-font tracking-wider text-sm">
                        NEVIN JOSE
                      </h2>
                      <p className="text-gray-700">Business Owner <a rel="noreferrer" href="https://www.etsy.com/shop/SakuraBlossomPrints?ref=shop-header-name&listing_id=1402129412&from_page=listing" target="_blank" className="hover:text-dark-pastel-blue"><br className="md:hidden sm:block"></br> - Sakura Blossom Prints</a></p>
                    </motion.section>
                  </div>
                </div>
              </section>
              
              <Footer />
            </header>
          </div>
        </div>
      )}
    </FileHandler>
  );
}

export default Landing;
