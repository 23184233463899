import { fabric } from 'fabric';

export const addImage = (canvas, setImg, actualImg) => {

    var legimg = actualImg
    legimg.set({ left: 0, top: 0, selectable: false })
    legimg.scaleToWidth(canvas.width)
    // legimg.scaleToHeight(canvas.height)
    canvas.add(legimg);
    // legimg.sendToBack();
    setImg(legimg);
    // canvas.renderAll()
    const relativeHeight =   legimg.height / legimg.width * canvas.width;
    canvas.setHeight(relativeHeight);

}


export const addRect = (canvas, setRect, rectData, design, changeRectCoords, changeRectDims, actualImg) => {
    var testRect = null
    const widthRatio = actualImg.width / canvas.width;
    const heightRatio = actualImg.height / canvas.height;

    if (design != null) {
        let x = null
        new fabric.Image.fromURL(design, img => {
            // console.log("img dims", img.height, img.width)
            // console.log("rectangle dims", rectData.rectangle_dims[0] , rectData.rectangle_dims[1])
            // console.log("what is scale x", rectData.scaleX)
            const scaleX = typeof rectData.scaleX == 'undefined' ? rectData.rectangle_dims[0] / img.width : rectData.scaleX;
            const scaleY = typeof rectData.scaleY == 'undefined' ? rectData.rectangle_dims[1] / img.height : rectData.scaleY;
            img.set({
                left: rectData.rectangle_coords[0],
                top: rectData.rectangle_coords[1],
                scaleX: scaleX,
                scaleY: scaleY,
                angle: rectData.rectangle_orientation
            })
            changeRectCoords([img.left * widthRatio, img.top * heightRatio])
            changeRectDims([img.scaleX * img.width * widthRatio , img.scaleY * img.height * heightRatio ])


            img.on('moving', function (event) {
                changeRectCoords([img.left * widthRatio, img.top * heightRatio])
            });

            img.on('scaling', (options) => {
                // console.log('scaling', actualImg.width, actualImg.height, canvas.width, canvas.height)
                // console.log(img.scaleX)
               changeRectDims([img.scaleX * img.width * widthRatio , img.scaleY * img.height * heightRatio ])
      
            });

            setRect(img);
            canvas.add(img);
            canvas.setActiveObject(img)
            x = img
        })


    } else {
        testRect = new fabric.Rect({
            height: 20,
            width: 20,
            fill: '#6b7280',
            borderColor:'#fafafa',
            rx: 0,
            ry: 0,
        });

        testRect.set({
            left: rectData.rectangle_coords[0],
            top: rectData.rectangle_coords[1],
            width: rectData.rectangle_dims[0],
            height: rectData.rectangle_dims[1],
            angle: rectData.rectangle_orientation
        })
        changeRectCoords([testRect.left * widthRatio, testRect.top * heightRatio])
        changeRectDims([testRect.width * widthRatio , testRect.height * heightRatio])

        testRect.on('moving', function (event) {
            changeRectCoords([testRect.left * widthRatio, testRect.top * heightRatio])
          });
    


        testRect.on('scaling', (options) => {
            testRect.set({
                width: testRect.width * testRect.scaleX,
                height: testRect.height * testRect.scaleY,
                scaleX: 1,
                scaleY: 1,
                noScaleCache: false,
            })
            // console.log('scaling', actualImg.width, actualImg.height, canvas.width, canvas.height)     
            // console.log('computed',testRect.width * widthRatio, testRect.height * heightRatio )
            changeRectDims([testRect.width * widthRatio , testRect.height * heightRatio])
            canvas.renderAll()
        });
        setRect(testRect);
        canvas.add(testRect);
        canvas.setActiveObject(testRect)
        return testRect
    }



}

export const setUpRect = (canvas, testRect, rectData) => {
    // TODO: add scaling numbers
    console.log(testRect)
    testRect.on('scaling', (options) => {
        testRect.set({
            width: testRect.width * testRect.scaleX,
            height: testRect.height * testRect.scaleY,
            scaleX: 1,
            scaleY: 1,
            noScaleCache: false,
        })
        canvas.renderAll()
    });
    if (rectData != null){
        testRect.set({
            left: rectData.rectangle_coords[0],
            top: rectData.rectangle_coords[1],
            scaleX: rectData.rectangle_dims[0] ,
            scaleY: rectData.rectangle_dims[1],
            angle: rectData.rectangle_orientation
        })
    }else if(rectData != null){
        testRect.set({
            left: rectData.rectangle_coords[0],
            top: rectData.rectangle_coords[1],
            width: rectData.rectangle_dims[0] ,
            height: rectData.rectangle_dims[1],
            angle: rectData.rectangle_orientation
        })
    }
    canvas.add(testRect);
    
}

export const arrayBufferToDataUrl = (arrayBuffer) => {
    const blob = new Blob([arrayBuffer], { type: 'image/png' }); // Adjust the MIME type as needed
    const dataUrl = URL.createObjectURL(blob);
    return dataUrl;
  }

