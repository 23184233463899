const buttonData = [
  {
    src: "/tops.svg",
    name: "Shirts",
    dropdownItems: ["T-Shirts", "Long-Sleeves", "Crop-Tops"],
    links:['tshirt_1.jpg','tshirt_2.jpg', 'tshirt_3.jpg']
  },
  {
    src: "/outerwear.svg",
    name: "Jackets",
    dropdownItems: ["Jackets", "Hoodies", "Sweaters"],
    links:["sweat_1.jpg", "sweat_2.jpg", "sweat_3.jpg"]
  },
  {
    src: "/poster.svg",
    name: "Posters",
    dropdownItems: ["Jeans", "Sweatpants", "Shorts"],
    links:['poster-1.jpg','poster-2.jpg','poster-3.jpg']
  },
  {
    src: "/headwear.svg",
    name: "Hats",
    dropdownItems: ["Baseball Caps", "Beanies", "Bucket Hats"],
    links:['cap-1.jpg','cap-2.jpg', 'cap-3.jpg']
  },
  {
    src: "/mugs.svg",
    name: "Mugs",
    dropdownItems: ["Sneakers", "Socks", "Sandals"],
    links:['mug-1.avif','mug-2.jpg','mug-3.jpg']
  },
  {
    src: "/accessories.svg",
    name: "Bags",
    dropdownItems: ["Bags", "Jewelry", "Sunglasses"],
    links:[]
  },
  {
    src: "/electronics.svg",
    name: "Cases",
    dropdownItems: ["Phone Cases", "Laptop Cases", "Headphones"],
    links:['case-1.jpg','case-2.webp','case-3.webp']
  },
  {
    src: "/others.svg",
    name: "Other",
    dropdownItems: ["Stickers", "Posters", "Mugs"],
    links:[]
  },
];

export default buttonData;
