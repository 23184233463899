import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import {
  createBrowserRouter,
  RouterProvider,
  Route,
  createRoutesFromElements,

} from "react-router-dom";
import GenerateTemplates from './components/GenerateTemplates';
import Home from './components/Home';
import CreateTemplates from './components/CreateTemplates';
import EditTemplate from './components/EditTemplate';
import Landing from './components/Landing';
import { Button } from 'antd';
import { HomeOutlined } from '@ant-design/icons';
import SiteHeader from './components/SiteHeader';
import { Outlet } from 'react-router-dom';

// const router = createBrowserRouter([
//   {
//     path: "/",
//     element: <Home />,
//   },
//   {
//     path: "/create-template",
//     element: <CreateTemplates />,
//   },
//   {
//     path: "generate-template",
//     element: <GenerateTemplates />,
//   },
//   {
//     path: "/edit-template",
//     element: <EditTemplate />,
//   },
//   {
//     path:'/landing',
//     element:<Landing/>
//   }
// ]);
const Layout = () => (
  <>
    <SiteHeader />
    <div className='w-screen h-screen lg:mt-32 '>

    <Outlet />
    </div>
  </>
);
const router = createBrowserRouter(
  createRoutesFromElements(
    
    <Route path="/" element={<Layout />}>
      <Route path="" element={<Landing />} />

      <Route path="home" element={<Home />} />

      <Route path="create-template" element={<CreateTemplates />} />
      <Route path='generate-template' element={<GenerateTemplates />} />
      <Route path='edit-template' element={<EditTemplate />} />

    </Route >
  )
);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <div>
    <RouterProvider router={router} />
  </div>



);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
