import logo from './logo.svg';
import './App.css';
import CreateTemplates from './components/CreateTemplates';
import { IndexedDB } from "react-indexed-db-hook";
import { DBConfig } from "./DBConfig";
import { initDB } from "react-indexed-db-hook";
import { useNavigate } from 'react-router-dom';
import { Button } from 'antd';
import { HomeOutlined } from '@ant-design/icons';
initDB(DBConfig);

function App() {
  // const navigate = useNavigate();
  return (
    <div className="">
      
    </div>
  );
}

export default App;
