
import { useEffect, useState, useRef } from 'react';

import { fabric } from 'fabric';
import { Button } from 'antd';
import 'react-alice-carousel/lib/alice-carousel.css';
import TemplateEditor from './TemplateEditor.js'
import { useNavigate } from 'react-router-dom';



function CreateTemplates() {
    const [uppercommit, setCommit] = useState(false);
    const navigate = useNavigate();

    const customButton = () =>
    (
        <Button  className=" mt-2 bg-white text-[#78716c] w-5/6 h-auto hover:bg-pastel-blue"  onClick={() => { setCommit(!uppercommit) }}>Create Template<br/> Collection</Button>
    );

    return (
        <div className='create-templates'>
            <TemplateEditor commit={uppercommit} changeLog={(x) => { }} customButton={customButton} />
        </div>

    );
}

export default CreateTemplates;