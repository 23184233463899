import { useEffect, useState } from "react";




function CollageBar({ designs, setDesign }) {
    useEffect(() => {
        // setDesign(designs[0])
    }, [])
    return (
        <div className="grid grid-cols-2 md:grid-cols-2 gap-4  h-80 overflow-y-scroll  rounded-xl bg-white shadow-2xl p-5 mt-4 ">
            {designs ? designs.map((design, index) => (
                <div key={index}>
                    <button onClick={() => { setDesign(design) }}>
                        <img className="h-auto max-w-full rounded-lg" src={design.data} alt="" />
                    </button>
                </div>
            )) : <div></div>}
        </div>

    );
}

export default CollageBar;