const Footer = () => {
  return (
    <footer className="text-slate-600 body-font">
      <div className="container px-5 py-8 mx-auto flex sm:flex-row flex-col items-center">
        <a
          href="/"
          className="flex title-font font-medium items-center md:justify-start justify-center text-slate-900"
        >
          <img
            src="./templitLogo.svg"
            alt="Logo"
            className="w-10 h-10 p-2 border-2 border-pastel-blue rounded-full"
          />
          <span className="ml-3 text-xl">Templit</span>
        </a>
        <p className="text-sm text-slate-500 sm:ml-4 sm:p-4 sm:border-x-2 sm:border-slate-200 sm:py-2 sm:mt-0 mt-4">
          © 2024 Templit
        </p>
        <span className="sm:ml-1  text-sm text-slate-500  sm:p-4 sm:border-r-2 sm:border-slate-200 sm:py-2 sm:mt-0 mt-4">
          <a href="https://docs.google.com/forms/d/e/1FAIpQLSdxAez1bJOUgsiym0FbYl6xlCcEIIryZOWOwX6rYXjdMNLyjw/viewform?usp=sf_link" rel="noreferrer" target="_blank" className="hover:text-dark-pastel-blue">
            Feedback form
          </a>
        </span>
        <span className="sm:ml-4  text-sm text-slate-500">
          <a href="./terms_of_service_templit.pdf" rel="noreferrer" target="_blank" className="hover:text-dark-pastel-blue">
            Terms of Service
          </a>
        </span>
        
        <span className="inline-flex sm:ml-auto sm:mt-0 mt-4 justify-center sm:justify-start items-center">
          <a href="https://www.tiktok.com/@templitdesign" rel="noreferrer" target="_blank" className="text-slate-500">
          <svg
              fill="currentColor"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              viewBox="0 0 32 32"
              version="1.1"
              xmlns="http://www.w3.org/2000/svg"
              className="w-5 h-5"
            >
              <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
              <g
                id="SVGRepo_tracerCarrier"
                stroke-linecap="round"
                stroke-linejoin="round"
              ></g>
              <g id="SVGRepo_iconCarrier">
                {" "}
                <title>tiktok</title>{" "}
                <path d="M16.656 1.029c1.637-0.025 3.262-0.012 4.886-0.025 0.054 2.031 0.878 3.859 2.189 5.213l-0.002-0.002c1.411 1.271 3.247 2.095 5.271 2.235l0.028 0.002v5.036c-1.912-0.048-3.71-0.489-5.331-1.247l0.082 0.034c-0.784-0.377-1.447-0.764-2.077-1.196l0.052 0.034c-0.012 3.649 0.012 7.298-0.025 10.934-0.103 1.853-0.719 3.543-1.707 4.954l0.020-0.031c-1.652 2.366-4.328 3.919-7.371 4.011l-0.014 0c-0.123 0.006-0.268 0.009-0.414 0.009-1.73 0-3.347-0.482-4.725-1.319l0.040 0.023c-2.508-1.509-4.238-4.091-4.558-7.094l-0.004-0.041c-0.025-0.625-0.037-1.25-0.012-1.862 0.49-4.779 4.494-8.476 9.361-8.476 0.547 0 1.083 0.047 1.604 0.136l-0.056-0.008c0.025 1.849-0.050 3.699-0.050 5.548-0.423-0.153-0.911-0.242-1.42-0.242-1.868 0-3.457 1.194-4.045 2.861l-0.009 0.030c-0.133 0.427-0.21 0.918-0.21 1.426 0 0.206 0.013 0.41 0.037 0.61l-0.002-0.024c0.332 2.046 2.086 3.59 4.201 3.59 0.061 0 0.121-0.001 0.181-0.004l-0.009 0c1.463-0.044 2.733-0.831 3.451-1.994l0.010-0.018c0.267-0.372 0.45-0.822 0.511-1.311l0.001-0.014c0.125-2.237 0.075-4.461 0.087-6.698 0.012-5.036-0.012-10.060 0.025-15.083z"></path>{" "}
              </g>
            </svg>

          </a>
          <a href="https://twitter.com/TemplitDesign" rel="noreferrer" target="_blank" className="ml-3 text-slate-500">
            <svg
              fill="currentColor"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              className="w-5 h-5"
              viewBox="0 0 24 24"
            >
              <path d="M23 3a10.9 10.9 0 01-3.14 1.53 4.48 4.48 0 00-7.86 3v1A10.66 10.66 0 013 4s-4 9 5 13a11.64 11.64 0 01-7 2c9 5 20 0 20-11.5a4.5 4.5 0 00-.08-.83A7.72 7.72 0 0023 3z"></path>
            </svg>
          </a>
          <a href="https://www.instagram.com/templitdesign/" rel="noreferrer" target="_blank" className="ml-3 text-slate-500">
            <svg
              fill="none"
              stroke="currentColor"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              className="w-5 h-5"
              viewBox="0 0 24 24"
            >
              <rect width="20" height="20" x="2" y="2" rx="5" ry="5"></rect>
              <path d="M16 11.37A4 4 0 1112.63 8 4 4 0 0116 11.37zm1.5-4.87h.01"></path>
            </svg>
          </a>
          <a href="localhost:3000" className="ml-3 text-slate-500">
            <svg
              fill="currentColor"
              stroke="currentColor"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="0"
              className="w-5 h-5"
              viewBox="0 0 24 24"
            >
              <path
                stroke="none"
                d="M16 8a6 6 0 016 6v7h-4v-7a2 2 0 00-2-2 2 2 0 00-2 2v7h-4v-7a6 6 0 016-6zM2 9h4v12H2z"
              ></path>
              <circle cx="4" cy="4" r="2" stroke="none"></circle>
            </svg>
          </a>
          
        </span>
      </div>
    </footer>
  );
};
export default Footer;
