
import { useNavigate } from 'react-router-dom';
import { Button } from 'antd';
import { HomeOutlined } from '@ant-design/icons';
import { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars } from "@fortawesome/free-solid-svg-icons";
import { useMediaQuery } from "react-responsive";
function SiteHeader() {
  const navigate = useNavigate();
  const isMobile = useMediaQuery({ query: "(max-width: 640px)" }); // TailwindCSS's breakpoint for small screens
  const [isOpen, setIsOpen] = useState(false);
  const toggleOpen = () => setIsOpen(!isOpen);
  return (
    //   <div className="">
    //       <nav className='!bg-white !bg-opacity-90 !backdrop-blur-lg !shadow-sm sticky w-full z-50 top-0 d-none-mobile-app flex'>
    //           <div className="mx-auto w-full px-8">
    //               <div className={"font-['Chivo'] text-4xl font-bold p-5"}>templit</div>
    //           </div>
    //           <div>
    //               <Button className='border-none mr-10 mt-5' icon={<HomeOutlined style={{ fontSize: '2rem' }} />}></Button>
    //           </div>
    //       </nav>
    //   </div>

    <div
      id="navbar"
      className="sticky top-0 left-0 right-0 bg-white p-3 md:p-4 h-fit w-full border-2 shadow-lg !bg-opacity-90 !backdrop-blur-lg "
    >
      <div className="sticky flex flex-row items-center justify-between">
        <div className="flex flex-row items-center">
          <a href="/">
            <div className="flex flex-row items-center">
              <img
                src="./templitLogo.svg"
                alt="logo"
                className="mr-4 w-10 h-10 md:w-12 md:h-12 lg:w-16 lg:h-16"
              ></img>
              <span className="justify-start text-3xl md:text-4xl lg:text-5xl text-black opacity-80 select-none">
                templit
              </span>
            </div>
          </a>
          {!isMobile && (
            <>
              <a href="localhost:3000" className="">
                <span className="opacity-50 text-base md:text-lg lg:text-xl ml-6 lg:ml-12 hover:opacity-100 text-nowrap" onClick={() => {navigate('/home')}}>
                  My mockups
                </span>
              </a>
              <a href="localhost:3000" className="hidden">
                <span className="opacity-50 text-xs md:text-lg lg:text-2xl ml-6 lg:ml-12 hover:opacity-100">
                  Pricing
                </span>
              </a>
            </>
          )}
        </div>
        {isMobile ? (
          <div style={{ position: "relative" }} className='hidden'>
            <FontAwesomeIcon
              icon={faBars}
              onClick={toggleOpen}
              className="text-2xl"
            />
            {isOpen && (
              <div className="absolute bg-white border rounded shadow-lg mt-2 py-1 z-10 right-0 top-full transform translate-x-[-10px] translate-y-2 text-nowrap">
                <button onClick={() => {navigate('/home')}}
                    className="block px-4 py-2 hover:bg-pastel-blue">
                    My mockups
                </button>
                {/* <a
                  href="localhost:3000"
                  className="block px-4 py-2 hover:bg-pastel-blue"
                >
                  Pricing
                </a>
                <a
                  href="localhost:3000"
                  className="block px-4 py-2 hover:bg-pastel-blue"
                >
                  Log in
                </a>
                <a
                  href="localhost:3000"
                  className="block px-4 py-2 hover:bg-pastel-blue"
                >
                  Sign up
                </a> */}
              </div>
            )}
          </div>
        ) : (
          <div className="flex flex-row items-center hidden">
            <a
              href="localhost:3000"
              className="opacity-50 text-sm md:text-lg lg:text-2xl ml-12 hover:opacity-100"
            >
              Log in
            </a>
            <button className="bg-pastel-blue p-3 px-5 rounded-full text-sm md:text-lg lg:text-2xl ml-12 hover:shadow-xl hover:bg-dark-pastel-blue">
              <h4 className="opacity-80 hover:opacity-100">Sign up</h4>
            </button>
          </div>
        )}
      </div>
    </div>
  );
}

export default SiteHeader;
