import '../styles/Canvas.css'
import SideControls from './SideControls';
import { useEffect, useState, useRef } from 'react';
import { fabric } from 'fabric';
import { addRect, setUpRect, addImage } from './canvasFunctions';
import CollageBar from './CollageBar';
import { useLocation } from 'react-router-dom';


function Canvas({ rectData, actualImg = null, changeRect, toggleRectangle = true, updateCanvas, designs = null, changingRect, customButton, files, setFiles, currentDesign, setCurrentDesign }) {

    const canvasRef = useRef(null);
    const [canvas, setCanvas] = useState(null);
    const [rect, setRect] = useState(null);
    const [img, setImg] = useState(null);
    const [rectCoords, setRectCoords] = useState([]);
    const [rectDims, setRectDims] = useState([])
    const location = useLocation();

    const initCanvas = () => {
        const tempCanvas = new fabric.Canvas(canvasRef.current, {
            height: window.innerWidth * .6,
            width: window.innerHeight * 0.7,
            backgroundColor: '#FFFF'
        })
        tempCanvas.on('mouse:down', (event) => {
            changingRect(true)
        });

        // Mouse up event
        tempCanvas.on('mouse:up', (event) => {
            changingRect(false)
        });
        return tempCanvas;
    };


    useEffect(() => {
        const tempCanvas = canvas || initCanvas(); // Use the existing canvas or create a new one if it doesn't exist
        // Handle window resize event to update canvas dimensions
        const handleResize = () => {
            tempCanvas.setDimensions({
                width: window.innerWidth * .5,
                height: window.innerHeight * 0.7,
            });

            tempCanvas.renderAll();
            setCanvas(tempCanvas)
        };

        // Initial setup
        handleResize();
        // Attach the event listener for window resize
        window.addEventListener('resize', handleResize);
        if (rect) {
            // rect.set({ visible: false });
            tempCanvas.remove(rect); // Remove the existing rectangle
            tempCanvas.renderAll();
            // setRect(null); // Reset the state
        }

        if (img) {
            tempCanvas.remove(img); // Remove the existing image if needed
            tempCanvas.renderAll();
            setImg(null); // Reset the state
        }

        if (actualImg) {
            addImage(tempCanvas, setImg, actualImg); // Add the new image
        }
        if (toggleRectangle && rectData != null && actualImg ) {
            console.log(rectData)
            const designParam = designs == null ? currentDesign : currentDesign.data
            const testRect = addRect(tempCanvas, setRect, rectData, designParam, setRectCoords, setRectDims, actualImg); // Add a new rectangle if needed
            changeRect(testRect)
        }
        setCanvas(tempCanvas); // Update the canvas state
        // Cleanup the event listener on component unmount
        // return () => {
        //     window.removeEventListener('resize', handleResize);
        //   };

    }, [actualImg, rectData, currentDesign]);


    useEffect(() => {
        if (canvas !== null) {
            updateCanvas(canvas)
        }

        if (rect) {
            changeRect(rect);
        }
    }, [canvas, rect, rectData])

    useEffect(() => {
        if(actualImg && canvas){         
            const widthRatio = actualImg.width / canvas.width;
            const heightRatio = actualImg.height / canvas.height;
            rect.set({
                left:rectCoords[0]/widthRatio,
                top:rectCoords[1]/heightRatio
    
            })
            canvas.renderAll()
        }
    }, [rectCoords])

    useEffect(() => {
        if(actualImg && canvas){
            const widthRatio = actualImg.width / canvas.width;
            const heightRatio = actualImg.height / canvas.height;
            if(designs != null){
                rect.set({
                    scaleX: rectDims[0]/ (rect.width * widthRatio),
                    scaleY: rectDims[1]/ (rect.height * heightRatio)
                })
            }else{
                rect.set({
                    width: rectDims[0] / widthRatio,
                    height: rectDims[1] / heightRatio
                })
            }
            canvas.renderAll()
          }
    }, [rectDims])

    // useEffect(() => {
    //     setCurrentDesign(designs[0]);
    // },[])




    return (
            <div className='flex h-auto w-full justify-center'>
                <div className='grow-[.5]'></div>
                <div className='rounded-xl bg-white shadow-2xl mt-4 relative ml-16 h-fit'>
                    <canvas id="c" ref={canvasRef} className='rounded-xl' />
                    <h1 className='absolute -bottom-20 left-2/4 text-[#78716c]'>{actualImg ? actualImg.width : ''}px</h1>
                    <h1 className='absolute bottom-2/4 -left-40 text-[#78716c]'>{actualImg ? actualImg.height : ''}px</h1>
                </div>
                <div className='flex flex-col ml-16 w-2/12'>

                <SideControls files={files} setFiles={setFiles}  customButton={customButton} rectCoords={rectCoords} rectDims={rectDims} setRectCoords={setRectCoords} setRectDims={setRectDims}/>
                {location.pathname == '/generate-template' ?  <CollageBar designs={designs} setDesign={setCurrentDesign}/>  : <div></div>}
                </div>

            </div>
    );
}

export default Canvas;