import React, { useEffect, useState } from 'react';
import { List, Card, Layout, Modal, Input, Upload, Button, Typography, Skeleton } from 'antd';
import { PlusOutlined, UploadOutlined, EditOutlined, DeleteOutlined } from '@ant-design/icons';
import '../styles/Generator.css';
import '../styles/Home.css'
import { useIndexedDB } from 'react-indexed-db-hook';
import { useNavigate } from "react-router-dom";

const { Text } = Typography;

const Home = () => {
    const [templateList, setTemplateList] = useState(null);
    const [modalOpen, setModalOpen] = useState(false);
    const [collectionName, setCollectionName] = useState(null);
    const [fileNames, setFileNames] = useState([]);
    const [createError, setCreateError] = useState(false);
    const [noFilesError, setNoFilesError] = useState(false);
    const { getAll, deleteRecord } = useIndexedDB('templateDB');
    const navigate = useNavigate();

    const EditTemplate = (files, collection) => {
        navigate('/edit-template', { state: { collectionName: collection, fileNames: files, toggleRectangle: true } });
    }
    const DeleteTemplate = (collectionObject) => {
        const keys = collectionObject.keys
        const index = templateList.indexOf(collectionObject);
        templateList.splice(index,1)
        setTemplateList([...templateList])

        keys.forEach((key) => {
            deleteRecord(key).then(() => {
            })
        })
        
    }

    const GenerateTemplate = (files, collection) => {
        navigate('/generate-template', { state: { collectionName: collection, fileNames: files, toggleRectangle: false } });
    }





    useEffect(() => {
        async function getAllTemplates() {
            const tempMap = new Map();
            const items = await getAll();
            items.forEach(item => {
                const existingItem = tempMap.get(item.collection);
                if (typeof existingItem == 'undefined') {
                    const templateCard = { collection: item.collection, images: [item.image_file], files: [item.file_object],keys:[item.id] }
                    tempMap.set(item.collection, templateCard)
                } else if (existingItem.images.length < 3) {
                    //update the images
                    existingItem.images.push(item.image_file)
                    existingItem.files.push(item.file_object)
                    existingItem.keys.push(item.id)
                    tempMap.set(item.collection, existingItem)
                } else {
                    // update the files
                    existingItem.files.push(item.file_object)
                    existingItem.keys.push(item.id)
                    tempMap.set(item.collection, existingItem)
                }
            })

            return tempMap


        }

        getAllTemplates().then((tempMap) => {
            const tempList = [];
            tempMap.forEach((value) => {
                tempList.push(value);
            });
            setTemplateList(tempList);
        })


    }, [])

    // fired when clicking the plus button
    const createTemplate = () => {
        if (( collectionName != null && collectionName.length > 0 ) && (fileNames!=null && fileNames.length >= 1)) {
            setModalOpen(false);
            navigate('/create-template', { state: { collectionName: collectionName, fileNames: fileNames, toggleRectangle: true } });
        } else {
            if(fileNames ==  null || fileNames.length < 1){
                setNoFilesError(true);
            }
    
            if(collectionName == null || collectionName.length <= 0){
                setCreateError(true);
            }
        }

       
    }

    useEffect(() => {
        setCreateError(collectionName != null && collectionName.length <= 0);
    }, [collectionName])

 



    const closeModal = () => {
        setModalOpen(false)
        setFileNames([]);
        setCollectionName(null);
        setNoFilesError(false);
        setCreateError(false);
    }

    const props = {
        onRemove: (file) => {
            const index = fileNames.indexOf(file);
            const newFileList = fileNames.slice();
            newFileList.splice(index, 1);
            setFileNames(newFileList);
        },
        beforeUpload: (file) => {
            const tempFiles = fileNames
            tempFiles.push(file)

            setFileNames(tempFiles)
            return false;
        }
    };

    useEffect(() => {}, [templateList])

    return (
        templateList != null && templateList.lengt === 0 ? <div></div> :
        <div className='list-wrapper'>
            {templateList != null ?
                <Layout style={{ overflowY: 'scroll' }}>

                    <Layout.Content style={{ padding: 30, marginTop: 15, height: '90vh', width: 'auto' }}>
                        <List

                            grid={{ gutter: 4, column: 1 }}
                            dataSource={templateList}
                            renderItem={(card, index) => (
                                <List.Item key={index}>
                                    <Card className='font-[Chivo] overflow-scroll  ' title={card.collection} style={{ margin: '16px 0' }}>
                                        <div className='flex'>
                                            <div className='flex'>
                                                {card.images.map((image, imgIndex) => (
                                                    <img
                                                        key={imgIndex}
                                                        src={image}
                                                        alt={`${imgIndex + 1}`}
                                                        className='ml-2 w-16 h-16'

                                                    />
                                                ))}
                                            </div>
                                            <div style={{ flexGrow: .85 }}></div>
                                            <div style={{ display: 'flex', justifyContent: 'space-between', width: '25%', height: '100%' }}>

                                                <button size={'large'} className='bg-white text-dark-pastel-purple border-2 border-dark-pastel-purple hover:text-white mr-3 px-4 rounded-2xl hover:bg-pastel-purple' onClick={() => GenerateTemplate(card.files, card.collection)} > Generate</button>
                                                <Button className='bg-white text-dark-pastel-blue border-2 border-dark-pastel-blue hover:bg-dark-pastel-blue hover:text-white' shape="circle" icon={<EditOutlined />} size={'large'} onClick={() => EditTemplate(card.files, card.collection)} />
                                                <Button shape="circle" className='ml-3 bg-white text-dark-pastel-red border-2 border-dark-pastel-red hover:bg-pastel-red hover:text-white' icon={<DeleteOutlined />} size={'large'} onClick={() => DeleteTemplate(card)} danger />
                                            </div>

                                        </div>
                                    </Card>
                                </List.Item>
                            )}
                        />
                    </Layout.Content>
                </Layout>
                : <div>
                    
                    <Layout style={{ overflowY: 'scroll' }}>

                    <Layout.Content style={{ padding: 30, marginTop: 15, height: '90vh', width: 'auto' }}>
                        <List grid={{ gutter: 4, column: 1 }}> 
                            
                            <Skeleton active className='mb-10' />
                            <Skeleton active className='mb-10' />
                            <Skeleton active className='mb-10' />
                            <Skeleton active className='mb-10' />
                            <Skeleton active className='mb-10' />
                            <Skeleton active className='mb-10' />
                        </List>
                    </Layout.Content>
                </Layout>
                    
                    </div>}

            <button className=' !backdrop-blur-lg  bg-gradient-to-tr from-[#86efac] to-[#86efac] shadow-inner hover:shadow-xl fixed right-12 bottom-12 rounded-full w-28 h-28 hover:bg-gradient-to-bl' onClick={() => setModalOpen(true)}>
                <PlusOutlined className='text-2xl text-white '  />
            </button>

            <Modal
                title="Enter Template Collection Name"
                centered
                open={modalOpen}
                onOk={createTemplate}
                onCancel={closeModal}
                width={'50vw'}
                okButtonProps={{ ghost: true }}

            >
                {createError ? <Text type='danger'>Enter a collection name</Text> : ''}
                {createError && noFilesError ? <Text type='danger'> and </Text> : ''}
                {noFilesError ? <Text type='danger'>Upload at least one file</Text> : ''}
                <Input placeholder='Collection Name' status={createError ? 'error' : ''} showCount maxLength={40} onChange={(e) => setCollectionName(e.target.value)} />
                <div style={{ marginTop: 10 }}>
                    <Upload {...props} fileList={fileNames} directory accept='image/png, image/jpeg'>
                        <Button icon={<UploadOutlined />}>Upload Files</Button>
                    </Upload>
                </div>
            </Modal>
        </div>
    );
};

export default Home;
