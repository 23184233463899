import { motion, useAnimation } from "framer-motion";
import { useEffect } from "react";

const HowTo = () => {
  const controls = useAnimation();
  const delay = 0.5; // delay between each animation

  useEffect(() => {
    controls.start((i) => ({
      x: 0,
      transition: { type: "just", stiffness: 120, delay: i * delay },
    }));
  }, [controls]);

  return (
    <main>
      <section className="text-gray-600 body-font lg:mt-96 bg-gradient-to-b from-white to-pastel-blue">
        <div className="container px-5 py-24 mx-auto">
          <div className="flex flex-col">
            <div className="bg-whiteflex relative flex-row justify-center pt-10 pb-20 items-center md:w-2/3 mx-auto rounded-full">
              <h1 className="title-font flex flex-row justify-center text-4xl md:text-6xl text-black opacity-80 mx-12">
                How to use
              </h1>
            </div>
          </div>
          <div className="flex flex-wrap sm:-m-4 -mx-4 -mb-10 -mt-4 rounded-3xl justify-center pb-12">
            <div className="flex flex-col md:flex-row w-full justify-between">
              <motion.div
                className="p-4 md:w-1/3 mb-6 mx-3 rounded-3xl h-fit bg-white"
                custom={0} // index of the animation
                animate={controls}
                initial={{ x: "-100vw" }}
              >
                <div className="">
                  <div className="rounded-lg overflow-hidden ">
                    <img
                      alt="content"
                      className="object-cover object-center h-full w-full"
                      src="./step_1.gif"
                    />
                  </div>
                  <h2 className="text-xl font-medium title-font text-gray-900 mt-5 bg-dark-pastel-blue">
                    Upload
                  </h2>
                  <p className="text-base leading-relaxed mt-2">
                    Upload, drag &amp; drop, or paste your mockup image&apos;s
                    files or folders.
                  </p>
                </div>
              </motion.div>
              <motion.div
                className="p-4 md:w-1/3 mb-6 mx-3 h-fit rounded-3xl bg-white"
                custom={1} // index of the animation
                animate={controls}
                initial={{ x: "-100vw" }}
              >
                <div className=" ">
                  <div className="rounded-lg overflow-hidden">
                    <img
                      alt="content"
                      className="object-cover object-center h-full w-full"
                      src="./step_2.gif"
                    />
                  </div>
                  <h2 className="text-xl font-medium title-font text-gray-900 mt-5 bg-dark-pastel-blue">
                    Placement Editing
                  </h2>
                  <p className="text-base leading-relaxed mt-2">
                    Upload your design and place it where you wish on the
                    mockups.
                  </p>
                </div>
              </motion.div>
              <motion.div
                className="p-4 md:w-1/3 mb-6 mx-3 rounded-3xl h-fit bg-white"
                custom={2} // index of the animation
                animate={controls}
                initial={{ x: "-100vw" }}
              >
                <div className="">
                  <div className="rounded-lg overflow-hidden">
                    <img
                      alt="content"
                      className="object-cover object-center h-full w-full"
                      src="./step_3.gif"
                    />
                  </div>
                  <h2 className="text-xl font-medium title-font text-gray-900 mt-5 bg-dark-pastel-blue">
                    Generate
                  </h2>
                  <p className="text-base leading-relaxed mt-2">
                    Press generate and watch your mockups appear in just seconds
                  </p>
                </div>
              </motion.div>
            </div>
          </div>
        </div>
      </section>
    </main>
  );
};
export default HowTo;
