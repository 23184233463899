import React, { useState } from 'react';
import TemplateEditor from './TemplateEditor';
import { List, Card, Checkbox, Layout, FloatButton, ConfigProvider, Modal, Input, Upload, Button, message, Typography } from 'antd';
import { UploadOutlined } from '@ant-design/icons';
import { useNavigate } from 'react-router-dom';
import SideControls from './SideControls'

const EditTemplate = () => {
    const [uppercommit, setCommit] = useState(false);
    const navigate = useNavigate();

    const customButton = () =>
    (
        <Button size='large' className=" mb-2 mt-2 bg-white text-[#78716c] w-5/6" onClick={() => { setCommit(!uppercommit) }}>Finalize Edits</Button>
    );

    return (
        <div className='create-templates'>
            <TemplateEditor commit={uppercommit} changeLog={(x) => {}} customButton={customButton}/>
        </div>
    )
};

export default EditTemplate;
