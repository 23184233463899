import { Button, InputNumber, Modal, Upload } from "antd";
import { useEffect, useState } from "react";
import { UploadOutlined } from '@ant-design/icons';
import { useLocation } from "react-router-dom";


function SideControls({ customButton, rectCoords, rectDims, setRectCoords, setRectDims, files, setFiles }) {
    const [modalOpen, setModalOpen] = useState(false);
    const [fileNames, setFileNames] = useState([]);
    const location = useLocation();

  

const addMockups = () => {
    const newFiles = fileNames.concat(files);
    setFiles(newFiles);
    closeModal();
}

const closeModal = () => {
    setModalOpen(false);
    setFileNames([]);
    // setCollectionName(null);
    // setNoFilesError(false);
    // setCreateError(false);
}

const props = {
    onRemove: (file) => {
        const index = fileNames.indexOf(file);
        const newFileList = fileNames.slice();
        newFileList.splice(index, 1);
        setFileNames(newFileList);
    },
    beforeUpload: (file) => {
        const tempFiles = fileNames
        tempFiles.push(file)

        setFileNames(tempFiles)
        return false;
    }
};

    return (
        <div className="p-3 rounded-xl bg-white shadow-2xl mt-4  h-fit  bg-[#d6d3d1] flex flex-col justify-center items-center w-fit" >
            <div className="flex items-start w-5/6">
                <h1 className="text-[#78716c]">
                    Coordinates(px):
                </h1>
            </div>
            <div className="flex justify-stretch w-5/6 mb-2">
                <InputNumber min={0} max={100000} className="mr-1 " value={parseInt(rectCoords[0])} onChange={(x) => { setRectCoords([x, rectCoords[1]]) }} />
                <InputNumber min={0} max={100000} className=" ml-1" value={parseInt(rectCoords[1])} onChange={(x) => { setRectCoords([rectCoords[0], x]) }} />
            </div>
            <div className="flex items-start w-5/6">
                <h1 className="text-[#78716c]">
                    Dimensions(px):
                </h1>
            </div>
            <div className="flex justify-stretch w-5/6 ">
                <InputNumber min={0} max={100000} className="mr-1 " value={parseInt(rectDims[0])} onChange={(x) => { setRectDims([x, rectDims[1]]) }} />
                <InputNumber min={0} max={100000} className=" ml-1" value={parseInt(rectDims[1])} onChange={(x) => { setRectDims([rectDims[0], x]) }} />
            </div>

            {location.pathname === '/edit-template' ?  <Button className=" mt-2 bg-white text-[#78716c] w-5/6 h-auto" icon={<UploadOutlined />}  onClick={() => setModalOpen(true)}>
                Add more <br/>Mockups
            </Button> : <div></div>}

           
            {customButton()}

            <Modal
                title="Add More Mockups"
                centered
                open={modalOpen}
                onOk={addMockups}
                onCancel={closeModal}
                width={'25vw'}
                okButtonProps={{ ghost: true }}
            >
                <Upload {...props} fileList={fileNames} multiple accept='image/png, image/jpeg'>
                    <Button icon={<UploadOutlined />}>Upload Files</Button>
                </Upload>
            </Modal>
        </div>
    );
}

export default SideControls;
